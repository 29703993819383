import React from "react"
import styles from "./conferenceItem.module.scss"

/**
 * 
 * @param {string} type 
 * @returns {string}
 */
const icon = (type) => {
    switch (type) {
        case 'Video':
            return <i class="material-icons">ondemand_video</i>
        case 'Slides':
            return <i class="material-icons">slideshow</i>
        case 'Link': 
        default:
            return <i class="material-icons">link</i>
    }
}

/**
 * 
 * @param {string} url 
 * @param {string} text 
 * @returns {string}
 */
const link = (url, text) => {
    console.log(url);
    return url ? <a className="g-link" href={url} target="_blank" rel="noopener noreferrer">{icon(text)} {text}</a> : '';
}

export default props => (
    <article className="post-item">
        <header className="post-item__header">
            <p className="post-item__meta">{props.conference}</p>
            <h1 className="post-item__title">{props.title}</h1>
        </header>
        <p className={styles.conferenceLinks}>
            {link(props.conferenceLink, 'Link')}
            {link(props.videoLink, 'Video')}
            {link(props.slidesLink, 'Slides')}
        </p>
    </article>
)