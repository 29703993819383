import React from "react"
import Layout from "../components/layout"
// import Video from "../components/video/video"
import Conference from "../components/conference/conference"

const WorksPage = () => (
    <Layout>
        <h1>Speaker</h1>
        <p>Ho partecipato come speaker ad alcune conferenze che ho elencato qui; dove possibile ho riportato i link a video e slide online</p>
        <p>Se vi interessa approfondire questi argomenti o averli nella vostra conferenza potete contattarmi nel modo che preferite.</p>
        <Conference/>
    </Layout>
)
export default WorksPage