import React from "react"
import Item from "../conferenceItem/conferenceItem"

export default () => (
    <div id="conferences-list">
        <Item title="Un'immagine vale più di mille righe di codice"
            conference="FullStackConf 2019 - Torino"
            conferenceLink="https://fullstackconf.it/schedule.html"
            slidesLink="https://nicola79dm.github.io/ImagesOptimizationSlides/#/"/>
        <Item title="Il workflow ai tempi del design system" 
            conference="Cssday 2019 - Faenza" 
            conferenceLink="https://2019.cssday.it/talks.html#nicola-dellamarina" 
            videoLink="https://www.youtube-nocookie.com/embed/t5MD5TQDhCw" 
            slidesLink="https://www.slideshare.net/nicoladellamarina/design-system-based-workflow"/>
        <Item title="Minimize downtime deploy with Magento 2.2"
            conference="Meet Magento 2018 - Venezia"
            conferenceLink="https://it.meet-magento.com/"/>
        <Item title="Scrivere codice CSS e dormire tranquilli"
            conference="Cssday 2015 - Faenza"
            conferenceLink="http://2015.cssday.it/#programma"
            videoLink="https://player.vimeo.com/video/127657746"
            slidesLink="https://www.slideshare.net/nicoladellamarina/scrivere-codice-css-e-dormire-tranquilli"/>
        <Item title="Magento Frontend Next Level"
            conference="Meet Magento 2015 - Milano"
            conferenceLink="https://it.meet-magento.com/" 
            videoLink="https://www.youtube-nocookie.com/embed/iLdwDtLHicA"/>
        <Item title="Prototyping con TYPO3" 
            conference="T3Camp 2014 - Milano" 
            conferenceLink="http://2014.t3campitalia.it/slide-video-typo3-t3camp-italia.html" 
            videoLink="https://player.vimeo.com/video/90974291" 
            slidesLink="https://www.slideshare.net/nicoladellamarina/prototyping-con-typo3"/>
        <Item title="Inviare newsletter con un servizio email marketing da TYPO3" 
            conference="T3Camp 2012 - Bologna" 
            conferenceLink="http://2012.t3campitalia.it/slide-video-typo3-t3camp-italia.html" 
            videoLink="https://player.vimeo.com/video/55342015"
            slidesLink="https://www.slideshare.net/nicoladellamarina/email-marketing-con-typo3"/>
        <Item title="Il mio primo sito TYPO3 Neos" 
            conference="T3Camp 2012 - Bologna" 
            conferenceLink="http://2012.t3campitalia.it/slide-video-typo3-t3camp-italia.html" 
            videoLink="https://player.vimeo.com/video/55511680" 
            slidesLink="https://www.slideshare.net/nicoladellamarina/il-mio-primo-sito-con-neos"/>
        <Item title="Template multi-device per il vostro TYPO3" 
            conference="T3Camp 2011 - Bologna" 
            conferenceLink="http://2011.t3campitalia.it/slide-video-typo3-t3camp-italia.html" 
            videoLink="https://player.vimeo.com/video/34498100" 
            slidesLink="https://www.slideshare.net/nicoladellamarina/template-multidevice-con-typo3"/>
        <Item title="Le 10 estensioni da tenere sempre a disposizione" 
            conference="T3Camp 2010 - Bologna" 
            conferenceLink="http://2010.t3campitalia.it/slide-e-video-presentazioni-1-edizione-t3camp-italia.html" 
            videoLink="https://player.vimeo.com/video/21227274" 
            slidesLink="https://www.slideshare.net/nicoladellamarina/le-10-estensioni-da-tenere-sempre-a-disposizione"/>
    </div>
)